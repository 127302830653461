import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[finaAutoFocus]',
})
export class AutoFocusDirective {

@Input('finaAutoFocus') isFocused: boolean = false;
@Output() backspacePressed: EventEmitter<void> = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isFocused'] && changes['isFocused'].currentValue) {
      this.elementRef.nativeElement.focus();
    }else{
      this.elementRef.nativeElement.blur();
    }
  }

   @HostListener('keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      this.backspacePressed.emit();
    }
  }
 }

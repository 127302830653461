<div class="autocomplete-container">
    <label *ngIf="label" class="input-label" for="inputElementRef">
        <span class="main-text">{{ label }}</span>
        <span>&nbsp;</span>
        <span class="text-gray-600">{{ labelSecondary }}</span>
    </label>
    <div class="autocomplete-input-group" #autocomplete>
          <input
            [formControl]="inputFormControl"
            #inputElementRef
            id="inputElementRef"
            type="text"
            name="{{name}}"
            placeholder="{{ placeholder }}"
            class="form-control box-input"
            [autocomplete]="'off'"
            [ngClass]="{
                'is-valid': inputFormControl.valid && inputFormControl.dirty && inputFormControl.value != '',
                'is-invalid': inputFormControl.touched && !inputFormControl.valid
            }"
            (click)="autocompleteMachine.send('FOCUS', { event: $event })"
        />
        <label
            class="autocomplete-input-group-append"
            for="inputElementRef"
            [ngClass]="{
                disabled: disabled
            }"
            (click)="disabled ? null : autocompleteMachine.send('CLICK_ON_ARROW', { event: $event })"
        >
            <fa-icon
                class="input-group-text autocomplete-input-group-icon"
                [icon]="['fas', 'angle-down']"
                [ngClass]="{ 'icon-disabled': disabled }"
            ></fa-icon>
        </label>
        <div *ngFor="let error of getErrorsMessages()" class="invalid-feedback">
            {{ error.message }}
        </div>
    </div>
    <ul *ngIf="autocompleteMachine.state === 'focussed_options_visible' && filteredList.length > 0" class="options">
        <li
            *ngFor="let option of filteredList | slice: 0:100"
            (mousedown)="autocompleteMachine.send('OPTION_SELECTED', {option})"
        >
            {{ option.label }}
        </li>
    </ul>
</div>

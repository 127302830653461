<div *ngIf="isPlan" class="d-flex justify-content-end mt-4 goTo mx-4"
    (click)="open(modalForm, {size: 'lg', centered: true})"
    >
        <div class="me-2">Registrar pago</div>
        <fa-icon class="icon" [icon]='["fas", "angle-right"]'></fa-icon>
</div>
<button *ngIf="!isPlan" class="btn btn-purple px-5 rounded-3"
(click)="open(modalForm, {size: 'md', centered: true})"
>
    Registrar pago
</button>

<form #Form="ngForm">
    <ng-template let-modal #modalForm>
        <div class="modal-header">
            <h5 class="modal-title">Registrar pago</h5>
            <div class="box-btn-close-modal">
                <button (click)="modal.close('Close Click')" class="btn-close-modal rounded-circle" type="button"
                    data-dismiss="modal" aria-label="Close"><img src="assets/icons/svg/icon-btn-close-modal.svg" class="" alt="icon-close"/>
                </button>
            </div>
        </div>
        <div class="modal-body d-flex justify-content-center aling-center">
            <div class="row">
                <label class="big mb-1" for="paymentMethod"><b>Resumen</b></label>
                <div class="col-12 mb-3">
                    <div class="mx-3 d-flex justify-content-between">
                        <label class="small mb-1" for="paymentMethod"><b>Mensualidad {{(plansService.plansBundle$|async).currentBillplan.paymentDatePlan | date: 'MMMM YYYY' : 'UTC' | titlecase }}</b></label>
                        <label class="small mb-1 ms-4">{{(payFormData.amountPlan) | currency: 'USD' : 'symbol' : '1.2-2' : 'en-US'}}</label>
                    </div>
                </div>
                <div class="col-12 mb-3" *ngIf="(plansService.plansBundle$|async)?.currentBillplan?.extraPayments.length > 0">
                    <label class="small mb-1 ms-3" for="paymentMethod"><b>Cargos extras</b></label>
                    <div class="ms-4" *ngFor="let extraPayment of (plansService.plansBundle$|async)?.currentBillplan?.extraPayments">
                        <div class="mx-3 d-flex justify-content-between">
                            <label class="small mb-1" for="paymentMethod"><b>{{extraPayment.name}}: </b></label>
                            <label class="small mb-1 ms-2"> {{extraPayment.amount | currency: 'USD' : 'symbol' : '1.2-2' : 'en-US'}}</label>
                        </div>
                    </div>
                </div>

                <div class="col-12 mb-3" *ngIf="(plansService.plansBundle$|async).currentBillplan?.plus > 0">
                    <div class="mx-3 d-flex justify-content-between">
                        <label class="small mb-1" for="paymentMethod"><b>IVA (16%)</b></label>
                        <label class="small mb-1 ms-4">{{(plansService.plansBundle$|async).currentBillplan?.plus | currency: 'USD' : 'symbol' : '1.2-2' : 'en-US'}}</label>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <div class="mx-3 d-flex justify-content-between">
                        <label class="small mb-1" for="paymentMethod"><b>Total a pagar</b></label>
                        <label class="small mb-1 ms-4">
                            {{payFormData.amount| currency: 'USD' : 'symbol' : '1.2-2' : 'en-US'}}
                        </label>
                    </div>
                </div>
                <hr class="px-2" />
                <div class="col-sm-12 mb-3 ms-3 d-flex align-items-center">
                    <label class="small mb-1" for="paymentMethod"><b>Método de pago</b></label>
                </div>
                <div class="col-12 mb-3">
                    <div class="d-flex justify-content-center align-items-center flex-wrap ms-3">
                        <div *ngFor="let paymentsMethod of paymentMethods; let i = index" class="">
                            <ng-container *ngIf="paymentsMethod.plus === commerceService.commerce.plus || (!commerceService.commerce.plus && paymentsMethod.plus === false)">
                                <img (click)="onChangePayment(paymentsMethod._id, i)" class="img-logo me-2 mt-1" [ngClass]="i === indexSelected ? 'border-purple' : ''" [src]="paymentsMethod.imgUrl" [alt]="paymentsMethod.name" />
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                        <div class="ms-3 d-flex justify-content-between">
                            <label *ngIf="paymentMethodSelected?.name"
                            class="small mb-1 me-4 " for="paymentMethod"><b>Cuenta:</b></label>
                            <label *ngIf="paymentMethodSelected?.name"
                            class="small mb-1 me-4 " for="paymentMethod">{{paymentMethodSelected?.name}}</label>
                        </div>
                        <div class="ms-3 d-flex justify-content-between">
                            <label *ngIf="paymentMethodSelected?.ownerName"
                            class="small mb-1 me-4 " for="paymentMethod"><b>Nombre:</b></label>
                            <label *ngIf="paymentMethodSelected?.ownerName"
                            class="small mb-1 me-4 " for="paymentMethod">{{paymentMethodSelected?.ownerName}}</label>
                        </div>
                        <div class="ms-3 d-flex justify-content-between">
                            <label *ngIf="paymentMethodSelected?.email"
                            class="small mb-1 me-4 " for="paymentMethod"><b>Correo:</b></label>
                            <label *ngIf="paymentMethodSelected?.email"
                            class="small mb-1 me-4 " for="paymentMethod">{{paymentMethodSelected?.email}}</label>
                        </div>
                        <div class="ms-3 d-flex justify-content-between">
                            <label *ngIf="paymentMethodSelected?.phone"
                            class="small mb-1 me-4 " for="paymentMethod"><b>Teléfono:</b></label>
                            <label *ngIf="paymentMethodSelected?.phone"
                            class="small mb-1 me-4 " for="paymentMethod">{{paymentMethodSelected?.phone}}</label>
                        </div>
                        <div class="ms-3 d-flex justify-content-between">
                            <label *ngIf="paymentMethodSelected?.dni"
                            class="small mb-1 me-4 " for="paymentMethod"><b>Cédula:</b></label>
                            <label *ngIf="paymentMethodSelected?.dni"
                            class="small mb-1 me-4 " for="paymentMethod">{{paymentMethodSelected?.dni}}</label>
                        </div>
                        <div class="ms-3 d-flex justify-content-between">
                            <label *ngIf="paymentMethodSelected?.currency?.code == 'VES'"
                            class="small mb-1 me-4 " for="paymentMethod"><b>Tasa:</b></label>
                            <label *ngIf="paymentMethodSelected?.currency?.code == 'VES'"
                            class="small mb-1 me-4 " for="paymentMethod">{{payFormData.exchangeRateBCV | currency: 'BS '}}</label>
                        </div>
                        <div class="ms-3 d-flex justify-content-between" *ngIf="paymentMethodSelected?.currency?.code">
                            <label class="small mb-1 me-4 " for="paymentMethod"><b>Monto total:</b></label>
                            <label class="small mb-1 me-4 " for="paymentMethod">{{(paymentMethodSelected?.currency?.code == 'USD' ? payFormData.amount : payFormData.amountVES) | currency: paymentMethodSelected?.currency?.code == 'VES' ? 'BS ' : 'USD'}}</label>
                        </div>
                </div>
                <hr class="px-2" />
                <div class="col-sm-12 mb-3 ms-3 d-flex align-items-center">
                    <label class="small mb-1 me-4 w-25" for="date"><b>Fecha de pago</b></label>
                    <div class="">
                        <!-- calendar  -->
                        <div class="dp-hidden position-absolute">
                            <div class="input-group">
                                <input
                                    name="datepicker"
                                    class="form-control"
                                    ngbDatepicker
                                    #datepicker="ngbDatepicker"
                                    [autoClose]="'outside'"
                                    (dateSelect)="onDateSelection($event, datepicker)"
                                    [displayMonths]="1"
                                    [dayTemplate]="t"
                                    outsideDays="hidden"
                                    [startDate]="fromDate!"
                                    tabindex="-1"
                                />
                                <ng-template #t let-date let-focused="focused">
                                    <span
                                        class="custom-day"
                                        [class.focused]="focused"
                                        (mouseenter)="hoveredDate = date"
                                        (mouseleave)="hoveredDate = null"
                                    >
                                        {{ date.day }}
                                    </span>
                                </ng-template>
                            </div>
                        </div>
                        <div class="input-group">
                            <input
                                #dpFromDate
                                class="form-control"
                                placeholder="Año-Mes-Dia"
                                name="dpFromDate"
                                disabled
                                [value]="formatter.format(fromDate)"
                                (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                                (change)="onDateSelection(null, null)"
                            />
                            <button class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()" type="button">
                                <fa-icon class="text-gray-200" [icon]='["fas", "calendar"]'></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 mb-3 ms-3 d-flex align-items-center">
                    <label class="small mb-1 me-4 w-25" for="ref"><b>Últimos 4 dígitos de la transacción</b></label>
                    <div class="">
                        <input class="form-control input-group" required
                            type="text" name="ref" id="ref" #ref="ngModel" [(ngModel)]="payFormData.ref"
                            placeholder="" autocomplete="off" minlength="4" maxlength="4"
                            [ngClass]="{'is-invalid': ref.touched && !ref.valid}"
                        >
                        <div class="invalid-feedback" *ngIf='!(ref.valid)'>Referencia requerida.</div>
                    </div>
                </div>
                <div class="col-sm-12 mb-3 ms-3 d-flex align-items-center">
                    <label class="small mb-1 me-4 w-25" for="issuing"><b>Nombre del emisor</b></label>
                    <div class="">
                        <input class="form-control input-group" required
                            type="text" name="issuing" id="issuing" #issuing="ngModel" [(ngModel)]="payFormData.issuing"
                            placeholder="" autocomplete="off"
                            [ngClass]="{'is-invalid': issuing.touched && !issuing.valid}"
                        >
                        <div class="invalid-feedback" *ngIf='!(issuing.valid)'>Emisor requerido.</div>
                    </div>
                </div>
                <div class="col-sm-12 mb-3 ms-3 d-flex align-items-center">
                    <label class="small mb-1 me-4 w-25" for="issuing"><b>Comentarios </b>(OPCIONAL)</label>
                    <div class="">
                        <textarea class="form-control input-group"
                            type="text" name="comments" id="comments" #comments="ngModel" [(ngModel)]="payFormData.comments"
                            placeholder="" autocomplete="off"
                            [ngClass]="{'is-invalid': comments.touched && !comments.valid}"
                        ></textarea>
                        <div class="invalid-feedback" *ngIf='!(comments.valid)'>Emisor requerido.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center my-5">
            <button class="btn btn-purple px-5" [disabled]="!Form.valid || loading"
            (click)="payPlan()"
            >
                Registrar pago
            </button>
        </div>
    </ng-template>
</form>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

import { User } from '../models';
import userPermissions from './user-permissions';

const userSubject$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
const tenant$: BehaviorSubject<any> = new BehaviorSubject<any>(null);


@Injectable()
export class UserService {

    API_URL = environment.API_URL;
    PERMISSIONS = {...userPermissions};

    constructor(private http: HttpClient) {
        // this.user = {
        //     id: '123',
        //     firstName: 'Valerie',
        //     lastName: 'Luna',
        //     email: 'vluna@aol.com',
        //     image: '/assets/img/illustrations/profiles/profile-1.png',
        // };
    }

    set user(user: User) {
        userSubject$.next(user);
    }

    get user$() {
        return userSubject$;
    }

    set tenant(tenant: any) {
        tenant$.next(tenant);
    }

    get tenant$() {
        return tenant$;
    }

    getTenant$() {
        return this.http.get<any>(`${this.API_URL}/tenants/tenantId`);
    }

    getLoginQuantity$(): Observable<any>{
        return this.http.get<any>(`${this.API_URL}/tenants/loginQuantity`);
    }

    retrieveUserData() {
        return this.http.get<User>(`${this.API_URL}/users/${userSubject$.getValue()._id}`).subscribe({
            next: (user) => {
                let newUser = new User(
                    user._id,
                    user.email,
                    user.name,
                    user.lastName,
                    user.tenantId,
                    userSubject$.getValue().token,
                    userSubject$.getValue().tokenExpirationDate,
                    user.roles,
                    user.phone,
                    user.birthday,
                    user.location,
                    user.enabled,
                    user.status,
                    user.permissions,
                    user.showNews,
                    user.instructionsNotToShow
                )
                this.user = newUser;
            }
        })
    }

    createNewUser(newUser: any) {
        return this.http.post<User>(`${this.API_URL}/users`, newUser);
    }

    updateUser(user: User) {
        return this.http.put<User>(`${this.API_URL}/users/${userSubject$.getValue()._id}`, user);
    }

    updateUserData(user: User, id:string) {
        return this.http.put<User>(`${this.API_URL}/users/associated-user/${id}`, user);
    }

    getUsersAssociated$() {
        return this.http.get<any>(`${this.API_URL}/users`);
    }

    deleteAssociatedUser$(associatedUserId:string) {
        return this.http.delete<any>(`${this.API_URL}/users/${associatedUserId}`);
    }

    changeUserEnablement$(associatedUserId:string, status:string) {
        return this.http.put<any>(`${this.API_URL}/users/changeEnablement/${associatedUserId}`, { status });
    }

}
